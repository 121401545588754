
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import { Accordion, AccordionSummary, AccordionDetails, Modal, TextField, MenuItem, Box, Button, Grid } from '@mui/material';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { htmlToDraft } from 'draft-js-import-html';
import { stateFromHTML } from 'draft-js-import-html';

import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import RichTextEditor from './../../Commonfields/RichTextEditorComponent';
import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";
import UpdateSurveyName from '../UpdateSurveyNamePopup/UpdateSurveyNamePopup';
import { fianlTemplate, surveyNames, updateSurveyNameVariant } from '../../../Constants/static';
import "./CreateSurveyPopup.css"

function AutoResponsePopup({ code1, isOpen, handleOKButton, isUpdated, initialSurveyName, reference, campaignCode, disabledflag, isEditedFlag, automaticReminder }) {

    let code= sessionStorage.getItem("setCampaignselector")
    let surveyArray = sessionStorage.getItem("surveyArray")
    if (surveyArray === null){
        surveyArray = []
    }


    var req_body = {
        "survey_name": "",
        "survey_desc": "",
        "reference": null,
        "reference_order": 0,
        "is_custom": 1,
        "campaign_code": campaignCode,
        "welcome_text": {
            "en": "",
            "es": "",
            "vi": ""
        },
        "is_previewed": 0,
        "survey_code": [],
        "survey_template": [{
            "ref_questions": [


            ],
            "ref_answers": {

            }
        }],
        "invite_reminder_list": [
            {
                "sms_text": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_subject": "",
                "email_body": "",
                "invite_code": "NRSMS",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": "",
                "email_subject": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_body": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "invite_code": "NREML",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_subject": "",
                "email_body": "",
                "invite_code": "FRSMS",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": "",
                "email_subject": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_body": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "invite_code": "FREML",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_subject": "",
                "email_body": "",
                "invite_code": "FNSMS",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": "",
                "email_subject": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_body": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "invite_code": "FNEML",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            }
        ]
    }

    const invitations_reminders = [
        {
            "id": "sms_invitation",
            "title": "SMS Invitation",
            "description": "SMS Invitation Description"
        },
        {
            "id": "email_invitation",
            "title": "Email Invitation",
            "description": "Email Invitation Description"
        },
        {
            "id": "first_reminder_sms",
            "title": "First Reminder SMS",
            "description": "First Reminder SMS Description"
        },
        {
            "id": "first_reminder_email",
            "title": "First Reminder Email",
            "description": "First Reminder Email Description"
        },
        {
            "id": "final_reminder_sms",
            "title": "Final Reminder SMS",
            "description": "Final Reminder SMS Description"
        },
        {
            "id": "final_reminder_email",
            "title": "Final Reminder Email",
            "description": "Final Reminder Email Description"
        },
    ]

    const initialFlags = {
        "email_invitation": false,
        "final_reminder_email": false,
        "final_reminder_sms": false,
        "first_reminder_email": false,
        "first_reminder_sms": false,
        "sms_invitation": false,
        "thankyou_template": false,
        "welcome_template": false,
        "question": false
    }


    const [payload, setPayload] = useState(req_body);
    const [expanded, setExpanded] = useState('panel1');
    const [focusStates, setFocusStates] = useState({
        promotionName: false,
        promotionDescription: false,
        question: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const [questionID, setQuestionID] = useState('');
    const [language, setLanguage] = useState('en');
    const [welcomeText, setWelcomeText] = useState(null);
    const [thankyouText, setThankyouText] = useState('');
    const [thankyouObject, setThankyouObject] = useState({});
    const [questionIndex, setQuestionIndex] = useState(null);
    const [surveyTemplateDisplay, setSurveyTemplateDisplay] = useState(true);
    const [welcomeTemplateDisplay, setWelcomeTemplateDisplay] = useState(false);
    const [thankYouTemplateDisplay, setThankYouTemplateDisplay] = useState(false);
    const [surveyQuestionDisplay, setSurveyQuestionDisplay] = useState(false);
    const [smsInvitationDisplay, setSmsInvitationDisplay] = useState(false);
    const [emailInvitationDisplay, setEmailInvitationDisplay] = useState(false);
    const [selectedSmsEmail, setSelectedSmsEmail] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [firstemailSubject, setFirstEmailSubject] = useState('');
    const [finalemailSubject, setFinalEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [firstemailBody, setFirstEmailBody] = useState('');
    const [finalemailBody, setFinalEmailBody] = useState('');
    const [messageText, setMessageText] = useState(null);
    const [firstmessageText, setFirstMessageText] = useState('');
    const [finalmessageText, setFinalMessageText] = useState('');
    const [bodyTextError, setBodyTextError] = useState('');
    const [isRichEditorFocused, setIsRichEditorFocused] = useState(false);

    const containerRef = useRef(null);
    const editorRef = useRef(null);
    const [result, setResult] = useState(null);

    const [previousValue, setPreviousValue] = useState('');
    const [currentValue, setCurrentValue] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');

    const [surveyName, setSurveyName] = useState(null)
    const [surveyDesc, setSurveyDesc] = useState(null)

    const [surveyNameError, setSurveyNameError] = useState(null);
    const [surveyDescError, setSurveyDescError] = useState(null);
    const [welcomeTextError, setWelcomeTextError] = useState(null);
    const [thankyouTextError, setThankyouTextError] = useState(null);

    const [isInfoDisplay, setIsInfoDisplay] = useState(true);
    const [isDetailsDisplay, setIsDetailsDisplay] = useState(false);

    const [questionError, setQuestionError] = useState('');
    const [messageTextError, setMessageTextError] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [questions, setQuestions] = useState([
        {
            question_id: "refQ1",
            question_text: {
                en: "",
                es: "",
                vi: ""
            },
            answer_type_id: "SSO",
            is_last_question: 0
        }
    ]);

    const [answers, setAnswers] = useState({
        "refQ1": [
            {
                "answer_id": "refA1",
                "answer_text": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "answer_type_id": "SSO",
                "future_question_id": 0,
                "future_questions": [],
                "is_copay": false,
                "is_selected": false,
                "next_question_id": "refQ2",
                "copay_future_id": 0,
                "priority": 0
            },
            {
                "answer_id": "refA2",
                "answer_text": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "answer_type_id": "SSO",
                "future_question_id": 0,
                "future_questions": [],
                "is_copay": false,
                "is_selected": false,
                "next_question_id": "refQ2",
                "copay_future_id": 0,
                "priority": 0
            }
        ]
    })
    const [selectedAnswers, setSelectedAnswers] = useState([])
    const initialFocusStates = Array.isArray(answers)
        ? answers.reduce((acc, _, index) => {
            acc[index] = false; // Initialize focus state for each text field
            return acc;
        }, {})
        : {};

    const [focusAnswerStates, setFocusAnswerStates] = useState([]);

    const [isDraft, setisDraft] = useState(false);

    const [surveyNameFocus, setSurveyNameFocus] = useState(false);
    const [surveyDescFocus, setSurveyDescFocus] = useState(false);
    const [welcomeTextFocus, setWelcomeFocus] = useState(false);

    const [childModalopen, setChildModalOpen] = useState(false);
    const [text, setText] = useState('');

    const [ surveyUpdateVariant, setSurveyUpdateVariant ] = useState("")
    const [ checkVariant, setCheckVariant ] = useState("")
    const [ thankyouSaveFlag, setThankyouSaveFlag] = useState(false)
    const [ flags, setFlags ] = useState(initialFlags)
    const [ deleteFlag, setDeleteFlag ] = useState(false)

    const isFirstLoad = useRef(true);

    const [ editedFlag, setEditedFlag] = useState(isEditedFlag)
    const [ infoLangChange, setInfoLangChange ] = useState(false)

    const resetState = () => {
        setPayload(req_body);
        setExpanded('panel1');
        setFocusStates({
            promotionName: false,
            promotionDescription: false,
            question: false,
        });
        setIsLoading(false);
        setQuestion('');
        setQuestionID('');
        setLanguage('en');
        setWelcomeText(null);
        setThankyouText('');
        setThankyouObject({});
        setQuestionIndex(null);
        setSurveyTemplateDisplay(true);
        setWelcomeTemplateDisplay(false);
        setThankYouTemplateDisplay(false);
        setSurveyQuestionDisplay(false);
        setSmsInvitationDisplay(false);
        setEmailInvitationDisplay(false);
        setSelectedSmsEmail('');
        setEmailSubject('');
        setFirstEmailSubject('');
        setFinalEmailSubject('');
        setEmailBody('');
        setFirstEmailBody('');
        setFinalEmailBody('');
        setMessageText(null);
        setFirstMessageText('');
        setFinalMessageText('');
        setBodyTextError('');
        setIsRichEditorFocused(false);
        setPreviousValue('');
        setCurrentValue('');
        setSelectedDescription('');
        setSurveyName(null);
        setSurveyDesc(null);
        setSurveyNameError(null);
        setSurveyDescError(null);
        setWelcomeTextError(null);
        setThankyouTextError(null);
        setIsInfoDisplay(true);
        setIsDetailsDisplay(false);
        setQuestionError('');
        setMessageTextError('');
        setSubjectError('');
        setQuestions([
            {
                question_id: "refQ1",
                question_text: {
                    en: "",
                    es: "",
                    vi: ""
                },
                answer_type_id: "SSO",
                is_last_question: 0
            }
        ]);
        setAnswers({
            "refQ1": [
                {
                    "answer_id": "refA1",
                    "answer_text": {
                        "en": "",
                        "es": "",
                        "vi": ""
                    },
                    "answer_type_id": "SSO",
                    "future_question_id": 0,
                    "future_questions": [],
                    "is_copay": false,
                    "is_selected": false,
                    "next_question_id": "refQ2",
                    "copay_future_id": 0,
                    "priority": 0
                },
                {
                    "answer_id": "refA2",
                    "answer_text": {
                        "en": "",
                        "es": "",
                        "vi": ""
                    },
                    "answer_type_id": "SSO",
                    "future_question_id": 0,
                    "future_questions": [],
                    "is_copay": false,
                    "is_selected": false,
                    "next_question_id": "refQ2",
                    "copay_future_id": 0,
                    "priority": 0
                }
            ]
        });
        setSelectedAnswers([]);
        setFocusAnswerStates([]); // Reinitialize focus states
        setisDraft(false);
        setSurveyNameFocus(false);
        setSurveyDescFocus(false);
        setWelcomeFocus(false);
        setChildModalOpen(false);
        setText('');
        setSurveyUpdateVariant('');
        setCheckVariant('');
        setThankyouSaveFlag(false);
        setFlags(initialFlags);
        setDeleteFlag(false);
    };



    function handleAnswerFocus(index) {
        setFocusAnswerStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = true;
            return newStates;
        });
    }

    function handleAnswerBlur(index) {
        setFocusAnswerStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = false;
            return newStates;
        });
    }

    function deleteAnswer(index) {
        if (selectedAnswers.length > 2) {
            setSelectedAnswers(prevAnswers => prevAnswers.filter((_, i) => i !== index));
            answers[questionID] = selectedAnswers.filter((_, i) => i !== index)
        }
    }

    const handleAnswerChange = (index, newValue) => {
        const updatedAnswers = [...selectedAnswers];
        // updatedAnswers[index] = { ...updatedAnswers[index], answer_text: { ...updatedAnswers[index].answer_text, [language]: newValue } };
        updatedAnswers[index].answer_text[language] = newValue;
        setSelectedAnswers(updatedAnswers);
        answers[questionID] = selectedAnswers
    };

    // Handler to update the expanded state
    const handleChange = (panel) => (event, isExpanded) => {
        if(expanded === 'all'){
            if(panel === 'panel1'){
                setExpanded("panel2")
            }else{
                setExpanded("panel1")
            }
        }else{
            setExpanded(isExpanded ? panel : false);
        }
    };

    // Event handler for focus
    const handleFocus = (fieldName, value) => {
        setPreviousValue(value);
        if (fieldName == 'surveyName') {
            setSurveyNameFocus(true)
        } else if (fieldName === 'surveyDescription') {
            setSurveyDescFocus(true)
        } else if (fieldName === 'welcomeText') {
            setWelcomeFocus(true)
        }
        setFocusStates({ ...focusStates, [fieldName]: true });
    };

    // Event handler for blur
    const handleBlur = (fieldName) => {
        console.log('Previous value:', previousValue);
        console.log('Current value:', currentValue);
        // setTimeout(() => {
        //     if (!inputRef.current.contains(document.activeElement)) {

        //         setFocusStates({ ...focusStates, [fieldName]: false });
        if (fieldName === 'surveyName') {
            // setSurveyNameFocus(false)
            if (currentValue === '') {
                setSurveyName(previousValue)
                setSurveyNameError('')
            }
        } else if (fieldName === 'surveyDescription') {
            // setSurveyDescFocus(false)
            if (currentValue === '') {
                setSurveyDesc(previousValue)
                setSurveyDescError('')
            }
        }
    };

    const handleTextFieldCancel = (fieldName) => {
        if (fieldName === 'surveyName') {
            setSurveyNameFocus(false)
            setSurveyName(previousValue)
        } else if (fieldName === 'surveyDescription') {
            setSurveyDescFocus(false)
            setSurveyDesc(previousValue)
        } else if (fieldName === 'welcomeText') {
            setWelcomeFocus(false)
            setWelcomeText(previousValue)
        }
    }

    const handleRichEditorFocus = () => {
        setIsRichEditorFocused(true);
    };

    const handleRichEditorBlur = () => {
        setIsRichEditorFocused(false);
    };

    const focusEditor = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    const handleSurveyNameChange = (e) => {
        setCurrentValue(e.target.value)
        setSurveyName(e.target.value);
        if (e.target.value.trim() === '') {
            setSurveyNameError('Survey name cannot be empty');
        } else {
            setSurveyNameError('');
        }

    }

    const handleupdatedSurveyName = (survey_name) => {
        setSurveyName(survey_name)
        setEditedFlag(true)
        //Need to add if conditions
    };

    const handleSurveyDescChange = (e) => {
        setCurrentValue(e.target.value)
        setSurveyDesc(e.target.value);
        if (e.target.value === '') {
            setSurveyDescError('Description cannot be empty');
        } else {
            setSurveyDescError('');
        }

    }

    const handleWelcomeTextChange = (newEditorState) => {
        setWelcomeText(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        const html = stateToHTML(contentState);
        const currentContent = newEditorState.getCurrentContent();
        if (currentContent.hasText() === false) {
            setWelcomeTextError('Welcome Text cannot be empty');
        } else {
            setWelcomeTextError('');
        }

    };

    const handleThankyouTextChange = (value) => {

        setThankyouText(value);

        const currentContent = value.getCurrentContent();
        if (currentContent.hasText() === false) {
            setThankyouTextError('Thank you text cannot be empty');
        } else {
            setThankyouTextError('');
        }
    };

    const messageTextChange = (event) => {
        if (selectedSmsEmail === 'sms_invitation') {
            setMessageText(event.target.value);
        } else if (selectedSmsEmail === 'first_reminder_sms') {
            setFirstMessageText(event.target.value)
        } else {
            setFinalMessageText(event.target.value)
        }
        if (event.target.value === '') {
            setMessageTextError('Message text cannot be empty');
        } else {
            setMessageTextError('');
        }
    };

    const emailSubjectChange = (event) => {
        if (selectedSmsEmail === 'email_invitation') {
            setEmailSubject(event.target.value);
        } else if (selectedSmsEmail === 'first_reminder_email') {
            setFirstEmailSubject(event.target.value)
        } else {
            setFinalEmailSubject(event.target.value)
        }
        if (event.target.value === '') {
            setSubjectError('Subject cannot be empty');
        } else {
            setSubjectError('');
        }
    };

    const handleEmailBodyTextChange = (value) => {
        const rawContent = convertToRaw(value.getCurrentContent());
        const htmlContent = stateToHTML(value.getCurrentContent());
        if (selectedSmsEmail === 'email_invitation') {
            setEmailBody(value);
        } else if (selectedSmsEmail === 'first_reminder_email') {
            setFirstEmailBody(value)
        } else {
            setFinalEmailBody(value)
        }


        const currentContent = value.getCurrentContent();
        if (currentContent.hasText() === false) {
            setBodyTextError('Body cannot be empty');
        } else {
            setBodyTextError('');
        }
    };

    const questionChange = (event) => {
        setQuestion(event.target.value);
        questions[questionIndex].question_text[language] = event.target.value
        if (event.target.value === '') {
            setQuestionError('Question cannot be empty');
        } else {
            setQuestionError('');
        }
    };

    const findDescriptionById = (id) => {
        const foundItem = invitations_reminders.find(item => item.id === id);
        if (foundItem) {
            setSelectedDescription(foundItem.description);
        } else {
            setSelectedDescription('');
        }
    };

    const infoSave = () => {
        if(!surveyNameError){
            setSurveyNameFocus(false)
            setSurveyDescFocus(false)
            if (surveyArray.includes(surveyName)){
                setSurveyUpdateVariant(updateSurveyNameVariant["save_info"])
            }
            else {
                payload.survey_name = surveyName
                payload.survey_desc = surveyDesc

                setPayload(payload)

                setIsInfoDisplay(false)
                setIsDetailsDisplay(true)
                isUpdated(true)
                if (code && code !== 'create_new' && isDraft) {

                    setIsLoading(true);
                    let apiurl = URL.UPDATE_SURVEY;
                    const body = {
                        "survey_code": [code],
                        "campaign_code": campaignCode,
                        "element_order" : 0,
                        "reference" : null,
                        "is_details": 1,
                        "survey_name": surveyName,
                        "survey_desc": surveyDesc,
                        "is_invite_reminder": 0,
                        "is_custom": true,
                        "is_survey_update": 0,
                        "welcome_text": {
                            "en": payload.welcome_text["en"],
                            "es": payload.welcome_text["es"],
                            "vi": payload.welcome_text["vi"]
                        },
                    }
                    Apiservice.patchMethod(apiurl, body).then(response => {
                        setIsLoading(false);
                        localStorage.removeItem('surveyCreation');
                        localStorage.removeItem('Survey_reference');

                    })
                        .catch(error => {
                            setIsLoading(false);
                            console.error('There was an error!', error);
                        })
                }

                else{
                    setIsLoading(true);
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 500);
                }
            }
        }
    }

    const welcomeSave = () => {


        if (surveyArray.includes(surveyName)){
            setSurveyUpdateVariant(updateSurveyNameVariant["save_welcome"])
        }

        else{
            setInfoLangChange(true);
            flags["welcome_template"] = false
            isUpdated(true)
            payload.welcome_text[language] = stateToHTML(welcomeText.getCurrentContent());
            // const html = stateToHTML(contentState);
            setWelcomeFocus(false)
            if (code && code != 'create_new' && isDraft) {

                setIsLoading(true);
                let apiurl = URL.UPDATE_SURVEY;
                const body = {
                    "survey_code": [code],
                    "campaign_code": campaignCode,
                    "element_order" : 0,
                    "reference" : null,
                    "is_details": 1,
                    "survey_name": surveyName,
                    "survey_desc": surveyDesc,
                    "is_invite_reminder": 0,
                    "is_custom": true,
                    "is_survey_update": 0,
                    "welcome_text": {
                        "en": payload.welcome_text["en"],
                        "es": payload.welcome_text["es"],
                        "vi": payload.welcome_text["vi"]
                    },
                }
                Apiservice.patchMethod(apiurl, body).then(response => {
                    setIsLoading(false);
                    localStorage.removeItem('surveyCreation');
                    localStorage.removeItem('Survey_reference');

                })
                    .catch(error => {
                        setIsLoading(false);
                        console.error('There was an error!', error);
                    })
            }

            else{
                setIsLoading(true);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
            }
        }
    }

    const thankyouSave = () => {

        if (surveyArray.includes(surveyName)){
            setSurveyUpdateVariant(updateSurveyNameVariant["save_thankyou"])
        }

        else{
            flags["thankyou_template"] = false
            isUpdated(true)
            const thankyou_text = {
                "en": language === 'en' ? stateToHTML(thankyouText.getCurrentContent()) : '',
                "es": language === 'es' ? stateToHTML(thankyouText.getCurrentContent()) : '',
                "vi": language === 'vi' ? stateToHTML(thankyouText.getCurrentContent()) : ''
            }

            setThankyouObject(thankyou_text)
            if (code && code != 'create_new' && isDraft) {
                isUpdated(true)
            }
        }
        setThankyouSaveFlag(true)

    }

    const prevQuestion = () => {
        if (questionIndex > 0) {

            getQuestion(questionIndex - 1)

            setQuestionIndex(questionIndex - 1)
        }
        if (containerRef.current) {
            const element = containerRef.current.children[questionIndex - 1];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const nextQuestion = () => {

        getQuestion(questionIndex + 1)

        setQuestionIndex(questionIndex + 1)
        if (containerRef.current) {
            const element = containerRef.current.children[questionIndex + 1];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    useEffect(()=>{
        questionAnswerSave()
        setThankyouSaveFlag(false)
    },[thankyouSaveFlag])


    const questionAnswerSave = () => {

        if (surveyArray.includes(surveyName)){
            setSurveyUpdateVariant(updateSurveyNameVariant["save_custom_qns"])
        }

        else{
            if(language === 'en'){
                flags["question"] = false
            }
            setInfoLangChange(true);
			isUpdated(true)
            console.log(questions)
			payload.survey_template[0].ref_questions = questions
			payload.survey_template[0].ref_answers = answers
			setPayload(payload)
			if (code && code !== 'create_new' && isDraft) {
				isUpdated(true)
				setIsLoading(true);

				if (thankyouText) {

					// Get the last question from the questions array
					const lastQuestion = questions[questions.length - 1];
					const lastId = lastQuestion?.question_id;

					// Ensure lastId is defined
					if (!lastId) {
						console.error('No last question ID found');
						return;
					}

					// Extract the numeric part from the last question ID
					const lastIdNumber = parseInt(lastId.replace('refQ', ''), 10);
					const newIdNumber = lastIdNumber + 1;

					const thankyou_text1 = {
						"en": language === 'en' ? stateToHTML(thankyouText.getCurrentContent()) : '',
						"es": language === 'es' ? stateToHTML(thankyouText.getCurrentContent()) : '',
						"vi": language === 'vi' ? stateToHTML(thankyouText.getCurrentContent()) : ''
					}

					// Create new question object
					const newQuestion = {
						question_id: `refQ${newIdNumber}`,
						question_text: thankyou_text1,
						answer_type_id: "FIN",
						is_last_question: 1
					};
					const updatedQuestions = [...questions, newQuestion];
					payload.survey_template[0].ref_questions = updatedQuestions;

					if (callbackChecked) {
						var newQuestionId = `refQ${newIdNumber}`

						const initialAnswersForNewQuestion = [
							{
                                "answer_id": "reft1",
                                "answer_text": {
                                    "en": "talk to an agent",
                                    "es": "Hablar con una agente",
                                    "vi": "nói chuyện với một đại lý"
                                },
                                "answer_type_id": "FIN",
                                "copay_future_id": 0,
                                "future_question_id": 0,
                                "future_questions": [],
                                "is_copay": false,
                                "is_selected": false,
                                "next_question_id": 0,
                                "priority": 0
                            },
                            {
                                "answer_id": "reft2",
                                "answer_text": {
                                    "en": "final action item",
                                    "es": "elemento de acción final",
                                    "vi": "mục hành động cuối cùng"
                                },
                                "answer_type_id": "FIN",
                                "copay_future_id": 0,
                                "future_question_id": 0,
                                "future_questions": [],
                                "is_copay": false,
                                "is_selected": false,
                                "next_question_id": 0,
                                "priority": 0
                            }
						];

						const updatedAnswers = {
							...answers,
							[newQuestionId]: [
								...(answers[newQuestionId] || []), // Merge with existing answers or initialize empty array if none exist
								...initialAnswersForNewQuestion
							]
						};
						payload.survey_template[0].ref_answers = updatedAnswers;

					}
                    else{
                        var newQuestionId = `refQ${newIdNumber}`

						const initialAnswersForNewQuestion = [
							{
                                "answer_id": "reft2",
                                "answer_text": {
                                    "en": "final action item",
                                    "es": "elemento de acción final",
                                    "vi": "mục hành động cuối cùng"
                                },
                                "answer_type_id": "FIN",
                                "copay_future_id": 0,
                                "future_question_id": 0,
                                "future_questions": [],
                                "is_copay": false,
                                "is_selected": false,
                                "next_question_id": 0,
                                "priority": 0
                            }
						];

						const updatedAnswers = {
							...answers,
							[newQuestionId]: [
								...(answers[newQuestionId] || []), // Merge with existing answers or initialize empty array if none exist
								...initialAnswersForNewQuestion
							]
						};
						payload.survey_template[0].ref_answers = updatedAnswers;
                    }
				}

				let apiurl = URL.UPDATE_SURVEY;
				payload.survey_template[0].ref_questions = payload.survey_template[0].ref_questions.map((q, index) => ({
					...q,
					question_id: `refQ${index + 1}`, // Generate ID as 'refQ1', 'refQ2', etc.
				}));

				const getNextAnswerId = (() => {
					let lastId = 0;
					return () => `refA${++lastId}`;
				})();

				const getNextQuestionKey = (() => {
					let lastIndex = 0;
					return () => `refQ${++lastIndex}`;
				})();

				payload.survey_template[0].ref_answers = Object.entries(payload.survey_template[0].ref_answers).reduce((acc, [key, answers], index) => {
					// Generate new key for the question
					const newQuestionKey = getNextQuestionKey();

					// Update answer_id incrementally
					const updatedAnswers = answers.map((answer) => ({
						...answer,
						answer_id: getNextAnswerId()
					}));

					acc[newQuestionKey] = updatedAnswers;
					return acc;
				}, {});


				const body = {
					"survey_code": [code],
					"campaign_code": campaignCode,
					"element_order" : 0,
					"reference" : null,
					"is_details": 0,
					"is_custom": true,
					"is_survey_update": 1,
					"is_invite_reminder": 0,
					"template" : [{
							"ref_questions": payload.survey_template[0].ref_questions,
							"ref_answers": payload.survey_template[0].ref_answers,
						}],

					"welcome_text": {
						"en": payload.welcome_text["en"],
						"es": payload.welcome_text["es"],
						"vi": payload.welcome_text["vi"]
					},
				}

				Apiservice.patchMethod(apiurl, body).then(response => {
					setIsLoading(false);
					localStorage.removeItem('surveyCreation');
					localStorage.removeItem('Survey_reference');

					// Step 1: Pop the last item from ref_questions and get its ID
					const lastQuestion = payload.survey_template[0].ref_questions.pop();
					const idToRemove = lastQuestion ? lastQuestion.id : null;


					// Step 2: Remove the item from ref_answers with the matching ID
					if (idToRemove) {
						const index = payload.survey_template[0].ref_answers.findIndex(answer => answer.id === idToRemove);
						if (index !== -1) {
							payload.survey_template[0].ref_answers.splice(index, 1);
						}
					}

					// At this point, the last item has been removed from both ref_questions and ref_answers


				})
					.catch(error => {
						setIsLoading(false);
						console.error('There was an error!', error);
					})
			}
			else{
				if (thankyouText) {

					// Get the last question from the questions array
					const lastQuestion = questions[questions.length - 1];
					const lastId = lastQuestion?.question_id;

					// Ensure lastId is defined
					if (!lastId) {
						console.error('No last question ID found');
						return;
					}

					// Extract the numeric part from the last question ID
					const lastIdNumber = parseInt(lastId.replace('refQ', ''), 10);
					const newIdNumber = lastIdNumber + 1;

					const thankyou_text1 = {
						"en": language === 'en' ? stateToHTML(thankyouText.getCurrentContent()) : '',
						"es": language === 'es' ? stateToHTML(thankyouText.getCurrentContent()) : '',
						"vi": language === 'vi' ? stateToHTML(thankyouText.getCurrentContent()) : ''
					}

					// Create new question object
					const newQuestion = {
						question_id: `refQ${newIdNumber}`,
						question_text: thankyou_text1,
						answer_type_id: "FIN",
						is_last_question: 1
					};

					const updatedQuestions = [...questions, newQuestion];
					payload.survey_template[0].ref_questions = updatedQuestions;

					if (callbackChecked) {
						var newQuestionId = `refQ${newIdNumber}`

						const initialAnswersForNewQuestion = [
							{
                                "answer_id": "reft1",
                                "answer_text": {
                                    "en": "talk to an agent",
                                    "es": "Hablar con una agente",
                                    "vi": "nói chuyện với một đại lý"
                                },
                                "answer_type_id": "FIN",
                                "copay_future_id": 0,
                                "future_question_id": 0,
                                "future_questions": [],
                                "is_copay": false,
                                "is_selected": false,
                                "next_question_id": 0,
                                "priority": 0
                            },
                            {
                                "answer_id": "reft2",
                                "answer_text": {
                                    "en": "final action item",
                                    "es": "elemento de acción final",
                                    "vi": "mục hành động cuối cùng"
                                },
                                "answer_type_id": "FIN",
                                "copay_future_id": 0,
                                "future_question_id": 0,
                                "future_questions": [],
                                "is_copay": false,
                                "is_selected": false,
                                "next_question_id": 0,
                                "priority": 0
                            }
						];

						const updatedAnswers = {
							...answers,
							[newQuestionId]: [
								...(answers[newQuestionId] || []), // Merge with existing answers or initialize empty array if none exist
								...initialAnswersForNewQuestion
							]
						};
						payload.survey_template[0].ref_answers = updatedAnswers;

					}
                    else{
                        var newQuestionId = `refQ${newIdNumber}`

						const initialAnswersForNewQuestion = [
                            {
                                "answer_id": "reft1",
                                "answer_text": {
                                    "en": "final action item",
                                    "es": "elemento de acción final",
                                    "vi": "mục hành động cuối cùng"
                                },
                                "answer_type_id": "FIN",
                                "copay_future_id": 0,
                                "future_question_id": 0,
                                "future_questions": [],
                                "is_copay": false,
                                "is_selected": false,
                                "next_question_id": 0,
                                "priority": 0
                            }
						];

						const updatedAnswers = {
							...answers,
							[newQuestionId]: [
								...(answers[newQuestionId] || []), // Merge with existing answers or initialize empty array if none exist
								...initialAnswersForNewQuestion
							]
						};
						payload.survey_template[0].ref_answers = updatedAnswers;
                    }
				}


				setIsLoading(true);
					setTimeout(() => {
						setIsLoading(false);
					}, 500);

			}
		}
    }

    const smsEmailSave = () => {
        if (surveyArray.includes(surveyName)){
            setSurveyUpdateVariant(updateSurveyNameVariant["save_inv"])
        }
        else{
            setInfoLangChange(true);
        isUpdated(true)
        if (selectedSmsEmail === 'sms_invitation') {
            flags["sms_invitation"] = false
            payload.invite_reminder_list[0].sms_text[language] = messageText
        } else if (selectedSmsEmail === 'email_invitation') {
            flags["email_invitation"] = false
            payload.invite_reminder_list[1].email_subject[language] = emailSubject
            payload.invite_reminder_list[1].email_body[language] = stateToHTML(emailBody.getCurrentContent())
        } else if (selectedSmsEmail === 'first_reminder_sms') {
            flags["first_reminder_sms"] = false
            payload.invite_reminder_list[2].sms_text[language] = firstmessageText
        } else if (selectedSmsEmail === 'first_reminder_email') {
            flags["first_reminder_email"] = false
            payload.invite_reminder_list[3].email_subject[language] = firstemailSubject
            payload.invite_reminder_list[3].email_body[language] = stateToHTML(firstemailBody.getCurrentContent())
        } else if (selectedSmsEmail === 'final_reminder_sms') {
            flags["final_reminder_sms"] = false
            payload.invite_reminder_list[4].sms_text[language] = finalmessageText
        } else if (selectedSmsEmail === 'final_reminder_email') {
            flags["final_reminder_email"] = false
            payload.invite_reminder_list[5].email_subject[language] = finalemailSubject
            payload.invite_reminder_list[5].email_body[language] = stateToHTML(finalemailBody.getCurrentContent())
        }
        setPayload(payload)


        if (code && code != 'create_new' && isDraft) {
            isUpdated(true)
            var invite_reminder_list
            if (selectedSmsEmail === 'sms_invitation') {
                invite_reminder_list = payload.invite_reminder_list[0]
            } else if (selectedSmsEmail === 'email_invitation') {
                invite_reminder_list = payload.invite_reminder_list[1]
            } else if (selectedSmsEmail === 'first_reminder_sms') {
                invite_reminder_list = payload.invite_reminder_list[2]
            } else if (selectedSmsEmail === 'first_reminder_email') {
                invite_reminder_list = payload.invite_reminder_list[3]
            } else if (selectedSmsEmail === 'final_reminder_sms') {
                invite_reminder_list = payload.invite_reminder_list[4]
            } else if (selectedSmsEmail === 'final_reminder_email') {
                invite_reminder_list = payload.invite_reminder_list[5]
            }
            setIsLoading(true);
            let apiurl = URL.UPDATE_SURVEY;
            const body = {
                "survey_code": [code],
                "campaign_code": campaignCode,
                "element_order" : 0,
                "reference" : null,
                "is_details": 0,
                "is_custom": false,
                "is_survey_update": 0,
                "is_invite_reminder": 1,
                "invite_reminder_list": [invite_reminder_list]
            }
            Apiservice.patchMethod(apiurl, body).then(response => {
                setIsLoading(false);
                localStorage.removeItem('surveyCreation');
                localStorage.removeItem('Survey_reference');

            })
                .catch(error => {
                    setIsLoading(false);
                    console.error('There was an error!', error);
                })
        }

        else{
            setIsLoading(true);
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
        }
    }

    }

    const surveyTemplate = () => {
        setSurveyTemplateDisplay(true)
        setWelcomeTemplateDisplay(false)
        setThankYouTemplateDisplay(false)
        setSurveyQuestionDisplay(false)
        setSmsInvitationDisplay(false)
        setEmailInvitationDisplay(false)
        setQuestionIndex(null)
        setSelectedSmsEmail('')
        setInfoLangChange(false);
    };

    const welcomeTemplate = () => {
        setSurveyTemplateDisplay(false)
        setWelcomeTemplateDisplay(true)
        setThankYouTemplateDisplay(false)
        setSurveyQuestionDisplay(false)
        setSmsInvitationDisplay(false)
        setEmailInvitationDisplay(false)
        setQuestionIndex(null)
        setSelectedSmsEmail('')
        setInfoLangChange(false);
    };

    const thankYouTemplate = () => {
        setSurveyTemplateDisplay(false)
        setWelcomeTemplateDisplay(false)
        setThankYouTemplateDisplay(true)
        setSurveyQuestionDisplay(false)
        setSmsInvitationDisplay(false)
        setEmailInvitationDisplay(false)
        setQuestionIndex(null)
        setSelectedSmsEmail('')
        setInfoLangChange(false);
    };

    const getQuestion = (index) => {
        setSurveyQuestionDisplay(true)
        setWelcomeTemplateDisplay(false)
        setThankYouTemplateDisplay(false)
        setSurveyTemplateDisplay(false)
        setSmsInvitationDisplay(false)
        setEmailInvitationDisplay(false)
        setQuestionIndex(index)
        setSelectedSmsEmail('')
        setInfoLangChange(false);
        setQuestionError('')

        let question_text = questions[index]?.question_text[language]

        setQuestion(question_text)
        setQuestionID(questions[index]?.question_id)
        let question_id = questions[index]?.question_id
        getAnswers(question_id)
    };

    const getAnswers = (question_id) => {
        setSelectedAnswers(answers[question_id])
    };

    const addAnswer = () => {
        // Get the last question ID
        const lastAnswer = selectedAnswers[selectedAnswers.length - 1];
        const lastId = lastAnswer.answer_id;

        // Extract the numeric part from the last question ID
        const lastIdNumber = parseInt(lastId.replace('refA', ''), 10);

		const newIdNumber = lastIdNumber + 1;

		const questionIdNum = parseInt(questionID.replace('refQ', ''), 10);
        const nextQuestionID = `refQ${questionIdNum+1}`

		// Create new question object
		const newAnswer = {
			answer_id: `refA${newIdNumber}`,
			answer_text: { en: "", es: "", vi: "" },
			answer_type_id: "SSO",
			future_question_id: 0,
			future_questions: [],
			is_copay: false,
			is_selected: false,
			next_question_id: nextQuestionID,
			copay_future_id: 0,
			priority: 0,
		};

		// Update the state with the new question added
		setSelectedAnswers([...selectedAnswers, newAnswer]);
    }

    const newQuestion = () => {
        // Get the last question ID
        if(code && code !== 'create_new'){
            flags['question'] = true
        }
        setInfoLangChange(false);
        const lastQuestion = questions[questions.length - 1];
        const lastId = lastQuestion.question_id;
        const lastAnswerId = answers[lastId][answers[lastId].length - 1].answer_id;

        const lastAnswerNumber = parseInt(lastAnswerId.replace('refA', ''), 10);


        // Extract the numeric part from the last question ID
        const lastIdNumber = parseInt(lastId.replace('refQ', ''), 10);
        const newIdNumber = lastIdNumber + 1;

        // Create new question object
        const newQuestion = {
            question_id: `refQ${newIdNumber}`,
            question_text: {
                en: "",
                es: "",
                vi: ""
            },
            answer_type_id: "SSO",
            is_last_question: 0
        };

        // Update the state with the new question added
        setQuestions([...questions, newQuestion]);
        var newQuestionId = `refQ${newIdNumber}`


        const initialAnswersForNewQuestion = [
            {
                answer_id: `refA${lastAnswerNumber+1}`,
                answer_text: { en: "", es: "", vi: "" },
                answer_type_id: "SSO",
                future_question_id: 0,
                future_questions: [],
                is_copay: false,
                is_selected: false,
                next_question_id: `refQ${newIdNumber+1}`,
                copay_future_id: 0,
                priority: 0,
            },
            {
                answer_id: `refA${lastAnswerNumber+2}`,
                answer_text: { en: "", es: "", vi: "" },
                answer_type_id: "SSO",
                future_question_id: 0,
                future_questions: [],
                is_copay: false,
                is_selected: false,
                next_question_id: `refQ${newIdNumber+1}`,
                copay_future_id: 0,
                priority: 0,
            }
        ];

        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [newQuestionId]: [
                ...(prevAnswers[newQuestionId] || []), // Merge with existing answers or initialize empty array if none exist
                ...initialAnswersForNewQuestion
            ]
        }));
    }

    const deleteQuestion = () => {
        if (questions.length > 1){

            const updatedQuestions = questions.filter(question => question.question_id !== questionID);
            // Updating qn_ids
            const finalQuestions = updatedQuestions.map(question => {
                // Extract the numerical part of the question_id
                const numericID = parseInt(question.question_id.replace('refQ', ''));

                // Create a new question_id if the numeric part is greater than the removed questionID
                const newQuestionID = numericID > parseInt(questionID.replace('refQ', '')) ? `refQ${numericID - 1}` : question.question_id;

                return {
                    ...question,
                   question_id: newQuestionID
                };
            });


            // setQuestions(updatedQuestions);
            setQuestions(finalQuestions);
            setQuestionIndex(questionIndex - 1)

            const updatedRefQuestions = payload.survey_template[0].ref_questions.filter(question => question.question_id !== questionID);
            payload.survey_template[0].ref_questions = updatedRefQuestions
            const updateAnswers = { ...answers };

            // delete updateAnswers[questionID];
                // Step 1: Filter out the question with the specified question_id
            const filteredData = Object.keys(updateAnswers).reduce((result, key) => {
                if (key !== questionID) {
                    result[key] = updateAnswers[key];
                }
                return result;
            }, {});

            // Step 2: Decrement the question IDs and update references
            const updatedData = Object.keys(filteredData).reduce((result, key) => {
                // Extract the numeric part of the current key
                const numericID = parseInt(key.replace('refQ', ''));

                // Generate the new key if the numeric part is greater than the removed questionID
                const newKey = `refQ${numericID > parseInt(questionID.replace('refQ', '')) ? numericID - 1 : numericID}`;

                // Update the next_question_id references
                const updatedAnswers = filteredData[key].map(answer => ({
                    ...answer,
                    next_question_id: parseInt(answer.next_question_id.replace('refQ', '')) > parseInt(questionID.replace('refQ', ''))  ? key : answer.next_question_id
                }));

                result[newKey] = updatedAnswers;
                return result;
            }, {});

            setAnswers(updatedData);
            setDeleteFlag(true)
            questionAnswerSave()

        //     if (questionIndex != 0) {
        //         getQuestion(questionIndex)
        //     } else {
        //         getQuestion(questionIndex )
        //     }
        }
    }


    useEffect(() => {
        if (isFirstLoad.current) {
            // Skip the effect on the initial render
            isFirstLoad.current = false;
        } else {
            // This will run only when surveyUpdateVariant changes, not on initial load
            if (questionIndex < questions.length){
                getQuestion(questionIndex)
            }
            else{
                getQuestion(questionIndex - 1)
            }

            setDeleteFlag(false)
        }
    }, [deleteFlag]);


    function handleClickcancel(data) {

        // if (code && code === 'create_new' && !isDraft) {
        payload.reference = null
        sessionStorage.setItem("setCampaignselector", "");
        payload.survey_template[0].ref_questions = questions
        payload.survey_template[0].ref_answers = answers


        localStorage.setItem('surveyCreation', JSON.stringify(payload));

        if (thankyouText) {
            // Get the last question from the questions array
            const lastQuestion = questions[questions.length - 1];
            const lastId = lastQuestion?.question_id;

            // Ensure lastId is defined
            if (!lastId) {
                console.error('No last question ID found');
                return;
            }

            // Extract the numeric part from the last question ID
            const lastIdNumber = parseInt(lastId.replace('refQ', ''), 10);
            const newIdNumber = lastIdNumber + 1;

            // Create new question object
            const newQuestion = {
                question_id: `refQ${newIdNumber}`,
                question_text: thankyouObject,
                answer_type_id: "FIN",
                is_last_question: 1
            };

            // Update the state with the new question added
            setQuestions(prevQuestions => {
                const updatedQuestions = [...prevQuestions, newQuestion];

                // Update localStorage with the new questions
                payload.survey_template[0].ref_questions = updatedQuestions;


                return updatedQuestions;
            });

            var newQuestionId = `refQ${newIdNumber}`
            console.log(newQuestionId,"newQuestionId");


            if (callbackChecked) {

                const initialAnswersForNewQuestion = [
                    {
                        "answer_id": "reft1",
                        "answer_text": {
                            "en": "talk to an agent",
                            "es": "Hablar con una agente",
                            "vi": "nói chuyện với một đại lý"
                        },
                        "answer_type_id": "FIN",
                        "copay_future_id": 0,
                        "future_question_id": 0,
                        "future_questions": [],
                        "is_copay": false,
                        "is_selected": false,
                        "next_question_id": 0,
                        "priority": 0
                    },
                    {
                        "answer_id": "reft2",
                        "answer_text": {
                            "en": "final action item",
                            "es": "elemento de acción final",
                            "vi": "mục hành động cuối cùng"
                        },
                        "answer_type_id": "FIN",
                        "copay_future_id": 0,
                        "future_question_id": 0,
                        "future_questions": [],
                        "is_copay": false,
                        "is_selected": false,
                        "next_question_id": 0,
                        "priority": 0
                    }
                ];

                setAnswers(prevAnswers => {
                    // Create updated answers object
                    const updatedAnswers = {
                        ...prevAnswers,
                        [newQuestionId]: [
                            ...(prevAnswers[newQuestionId] || []), // Merge with existing answers or initialize empty array if none exist
                            ...initialAnswersForNewQuestion
                        ]
                    };
                    console.log(updatedAnswers, "updatedAnswers");

                    // Update localStorage with the new answers
                    const updatedPayload = {
                        ...payload,
                        survey_template: [{
                            ...payload.survey_template[0],
                            ref_answers: updatedAnswers
                        }]
                    };

                    localStorage.setItem('surveyCreation', JSON.stringify(updatedPayload));

                    payload.survey_template[0].ref_answers = updatedAnswers;

                    // Return the updated state
                    return updatedAnswers;
                });


            }

            else{

                const initialAnswersForNewQuestion = [
                    {
                        "answer_id": "reft1",
                        "answer_text": {
                            "en": "final action item",
                            "es": "elemento de acción final",
                            "vi": "mục hành động cuối cùng"
                        },
                        "answer_type_id": "FIN",
                        "copay_future_id": 0,
                        "future_question_id": 0,
                        "future_questions": [],
                        "is_copay": false,
                        "is_selected": false,
                        "next_question_id": 0,
                        "priority": 0
                    }
                ];

                setAnswers(prevAnswers => {
                    // Create updated answers object
                    const updatedAnswers = {
                        ...prevAnswers,
                        [newQuestionId]: [
                            ...(prevAnswers[newQuestionId] || []), // Merge with existing answers or initialize empty array if none exist
                            ...initialAnswersForNewQuestion
                        ]
                    };
                    // Update localStorage with the new answers
                    const updatedPayload = {
                        ...payload,
                        survey_template: [{
                            ...payload.survey_template[0],
                            ref_answers: updatedAnswers
                        }]
                    };

                    localStorage.setItem('surveyCreation', JSON.stringify(updatedPayload));

                    payload.survey_template[0].ref_answers = updatedAnswers;

                    // Return the updated state
                    return updatedAnswers;
                });
            }
            // }
        }
        if (!(code && code !== 'create_new' && isDraft)) {
            console.log(payload,"payload");

            // for not created one
            localStorage.setItem('surveyCreation', JSON.stringify(payload));
        }
        else{
            console.log(payload,"payload");
            localStorage.removeItem('surveyCreation');
        }

        // Pass the value to the handleOK function of the main component from where the popup was called
        handleOKButton(data, flags , code, JSON.stringify(payload));
    }

    // Function to remove the last added question and its answers, and assign removed values to different variables
    const removeLastQuestionAndAnswers = () => {
        // Get the current questions and answers from state or localStorage
        const surveyCreationData = JSON.parse(localStorage.getItem('surveyCreation') || '{}');
        const currentQuestions = surveyCreationData.survey_template[0]?.ref_questions || [];
        const currentAnswers = surveyCreationData.survey_template[0]?.ref_answers || {};

        // Ensure there are questions to remove
        if (currentQuestions.length === 0) {
            console.error('No questions to remove');
            return;
        }

        // Get the last question
        const lastQuestion = currentQuestions[currentQuestions.length - 1];

        if (lastQuestion.answer_type_id === "FIN") {
            const lastId = lastQuestion?.question_id;

            if (!lastId) {
                console.error('No last question ID found');
                return;
            }

            // Store the removed question and its answers
            const removedQuestion = lastQuestion;
            const removedAnswers = currentAnswers[lastId] || [];

            // Remove the last question
            const updatedQuestions = currentQuestions.slice(0, -1);

            // Remove the answers for the last question
            const { [lastId]: _, ...updatedAnswers } = currentAnswers; // Destructure to remove the key

            // Update localStorage
            const updatedPayload = {
                ...surveyCreationData,
                survey_template: [{
                    ...surveyCreationData.survey_template[0],
                    ref_questions: updatedQuestions,
                    ref_answers: updatedAnswers
                }]
            };
            localStorage.setItem('surveyCreation', JSON.stringify(updatedPayload));

            // Update state
            setQuestions(updatedQuestions);
            setAnswers(updatedAnswers);

            // Return or store the removed question and answers if needed
            // return { removedQuestion, removedAnswers };

        }

    };


    const languageChange = (event) => {
        if(infoLangChange){
            setInfoLangChange(false)
        }
        setLanguage(event.target.value);
        if (surveyQuestionDisplay && questionIndex >= 0) {
            if(result?.data?.template?.[0]?.ref_questions?.[questionIndex]?.question_text?.[event.target.value]){
                setQuestion(result.data.template[0].ref_questions[questionIndex].question_text[event.target.value])
            }else if(payload?.survey_template?.[0]?.ref_questions?.[questionIndex]?.question_text?.[event.target.value]){
                setQuestion(payload.survey_template[0].ref_questions[questionIndex].question_text[event.target.value])
            }else{
                setQuestion('')
            }
        } else if (welcomeTemplateDisplay) {
            if(result?.data?.welcome_text?.[event.target.value]){
                setWelcomeText(convertHtmlToEditorState(result.data.welcome_text[event.target.value]))
            }else if(payload?.welcome_text?.[event.target.value]){
                setWelcomeText(convertHtmlToEditorState(payload.welcome_text[event.target.value]))
            }else{
                setWelcomeText('')
            }
        } else if (thankYouTemplateDisplay) {
            console.log(thankyouObject[event.target.value])
            if(thankyouObject[event.target.value]){
                setThankyouText(convertHtmlToEditorState(thankyouObject[event.target.value]))
            }else{
                setThankyouText('')
            }
        }

        if (selectedSmsEmail === 'sms_invitation') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setMessageText(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value])
            }
            else if (payload.invite_reminder_list[0].sms_text[event.target.value]) {
                setMessageText(payload.invite_reminder_list[0].sms_text[event.target.value])
            }

            else {
                setMessageText('')
            }
        } else if (selectedSmsEmail === 'first_reminder_sms') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setFirstMessageText(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value])
            }
            else if (payload.invite_reminder_list[2].sms_text[event.target.value]) {
                setFirstMessageText((payload.invite_reminder_list[2].sms_text[event.target.value]))
            }

            else {
                setFirstMessageText('')
            }
        } else if (selectedSmsEmail === 'final_reminder_sms') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setFinalMessageText(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value])
            }
            else if (payload.invite_reminder_list[4].sms_text[event.target.value]) {
                setFinalMessageText((payload.invite_reminder_list[4].sms_text[event.target.value]))
            }

            else {
                setFinalMessageText('')
            }
        } else if (selectedSmsEmail === 'email_invitation') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.subject?.[event.target.value]) {
                setEmailSubject(result.data.invitations_reminders[selectedSmsEmail].subject[event.target.value])
            }
            else if (payload.invite_reminder_list[1].email_subject[event.target.value]) {
                setEmailSubject(payload.invite_reminder_list[1].email_subject[event.target.value])
            }

            else {
                setEmailSubject('')
            }
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value]))
            }
            else if (payload.invite_reminder_list[1].email_body[event.target.value]) {
                setEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[1].email_body[event.target.value]))
            }

            else {
                setEmailBody('')
            }
        } else if (selectedSmsEmail === 'first_reminder_email') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.subject?.[event.target.value]) {
                setFirstEmailSubject(result.data.invitations_reminders[selectedSmsEmail].subject[event.target.value])
            }
            else if (payload.invite_reminder_list[3].email_subject[event.target.value]) {
                setFirstEmailSubject(payload.invite_reminder_list[3].email_subject[event.target.value])
            }

            else {
                setFirstEmailSubject('')
            }
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setFirstEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value]))
            }
            else if (payload.invite_reminder_list[3].email_body[event.target.value]) {
                setFirstEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[3].email_body[event.target.value]))
            }

            else {
                setFirstEmailBody('')
            }
        } else if (selectedSmsEmail === 'final_reminder_email') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.subject?.[event.target.value]) {
                setFinalEmailSubject(result.data.invitations_reminders[selectedSmsEmail].subject[event.target.value])
            }
            else if (payload.invite_reminder_list[5].email_subject[event.target.value]) {
                setFinalEmailSubject(payload.invite_reminder_list[5].email_subject[event.target.value])
            }

            else {
                setFinalEmailSubject('')
            }
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setFinalEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value]))
            }
            else if (payload.invite_reminder_list[5].email_body[event.target.value]) {
                setFinalEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[5].email_body[event.target.value]))
            }

            else {
                setFinalEmailBody('')
            }
        }

    };

    const getInvitationReminder = (data) => {
        setBodyTextError('')
        setSubjectError('')
        setMessageTextError('')
        setSelectedSmsEmail(data)
        findDescriptionById(data)
        setSurveyTemplateDisplay(false)
        setWelcomeTemplateDisplay(false)
        setThankYouTemplateDisplay(false)
        setSurveyQuestionDisplay(false)
        setQuestionIndex(null)
        setInfoLangChange(false);

        if (data === 'sms_invitation' || data === 'first_reminder_sms' || data === 'final_reminder_sms') {
            // setMessageText(result.data.invitaions_reminders[data].body[language])
            setSmsInvitationDisplay(true)
            setEmailInvitationDisplay(false)
        } else if (data === 'email_invitation' || data === 'first_reminder_email' || data === 'final_reminder_email') {
            // setEmailSubject(result.data.invitaions_reminders[data].subject[language])
            // setEmailBody(result.data.invitaions_reminders[data].body[language])
            setEmailInvitationDisplay(true)
            setSmsInvitationDisplay(false)

        }

        if (data === 'sms_invitation') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setMessageText(result.data.invitations_reminders[data].body[language])
            }
            else if (payload.invite_reminder_list[0].sms_text[language]) {
                setMessageText(payload.invite_reminder_list[0].sms_text[language])
            }

            else {
                setMessageText('')
            }
        } else if (data === 'first_reminder_sms') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setFirstMessageText(result.data.invitations_reminders[data].body[language])
            }
            else if (payload.invite_reminder_list[2].sms_text[language]) {
                setFirstMessageText((payload.invite_reminder_list[2].sms_text[language]))
            }

            else {
                setFirstMessageText('')
            }
        } else if (data === 'final_reminder_sms') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setFinalMessageText(result.data.invitations_reminders[data].body[language])
            }
            else if (payload.invite_reminder_list[4].sms_text[language]) {
                setFinalMessageText((payload.invite_reminder_list[4].sms_text[language]))
            }

            else {
                setFinalMessageText('')
            }
        } else if (data === 'email_invitation') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.subject?.[language]) {
                setEmailSubject(result.data.invitations_reminders[data].subject[language])
            }
            else if (payload.invite_reminder_list[1].email_subject[language]) {
                setEmailSubject(payload.invite_reminder_list[1].email_subject[language])
            }

            else {
                setEmailSubject('')
            }
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[data].body[language]))
            }
            else if (payload.invite_reminder_list[1].email_body[language]) {
                setEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[1].email_body[language]))
            }

            else {
                setEmailBody('')
            }
        } else if (data === 'first_reminder_email') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.subject?.[language]) {
                setFirstEmailSubject(result.data.invitations_reminders[data].subject[language])
            }
            else if (payload.invite_reminder_list[3].email_subject[language]) {
                setFirstEmailSubject(payload.invite_reminder_list[3].email_subject[language])
            }

            else {
                setFirstEmailSubject('')
            }
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setFirstEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[data].body[language]))
            }
            else if (payload.invite_reminder_list[3].email_body[language]) {
                setFirstEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[3].email_body[language]))
            }

            else {
                setFirstEmailBody('')
            }
        } else if (data === 'final_reminder_email') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.subject?.[language]) {
                setFinalEmailSubject(result.data.invitations_reminders[data].subject[language])
            }
            else if (payload.invite_reminder_list[5].email_subject[language]) {
                setFinalEmailSubject(payload.invite_reminder_list[5].email_subject[language])
            }

            else {
                setFinalEmailSubject('')
            }
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setFinalEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[data].body[language]))
            }
            else if (payload.invite_reminder_list[5].email_body[language]) {
                setFinalEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[5].email_body[language]))
            }

            else {
                setFinalEmailBody('')
            }
        }


    }

    const linkEntityStyleFn = (entity) => {
        if (entity.type === 'LINK') {
            return {
                element: 'a',
                attributes: {
                    href: entity.data.url,
                    target: '_blank',  // Add target="_blank"
                },
            };
        }
    };

    const convertHtmlToEditorState = (html) => {
        const blocksFromHTML = convertFromHTML(html);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        return EditorState.createWithContent(contentState);
    };

    const handleImageClick = (message) => {
        alert(message);
    };

    const inputRef = useRef(null);
    const [checked, setChecked] = useState(false);
    const [callbackChecked, setCallbackChecked] = useState(true);

    const fetchData = async () => {
        try {

            let endpoint = "?survey_code=" + code + "&campaign_code=" + campaignCode + "&reference=" + null;
            let url = URL.SURVEY_PREVIEW + endpoint;
            const response = await axios.get(url);
            setResult(response.data)
            // payload.survey_code = code
            payload.is_custom = 1

            setSurveyName(response.data.data.survey_name)
            setSurveyDesc(response.data.data.survey_description)
            payload.survey_code = response.data.data.survey_code
            payload.survey_desc = response.data.data.survey_description
            payload.survey_name = response.data.data.survey_name

            if(response.data.data.is_custom){

                setWelcomeText(convertHtmlToEditorState(response.data.data.welcome_text[language]))

                setFlags(response.data.data.flags)

                if( automaticReminder && (response.data.data.flags.question)&& (response.data.data.flags.email_invitation || response.data.data.flags.final_reminder_email || response.data.data.flags.final_reminder_sms || response.data.data.flags.first_reminder_email || response.data.data.flags.first_reminder_sms || response.data.data.flags.sms_invitation )){
                    setExpanded('all')
                }else if(automaticReminder && (response.data.data.flags.email_invitation || response.data.data.flags.final_reminder_email || response.data.data.flags.final_reminder_sms || response.data.data.flags.first_reminder_email || response.data.data.flags.first_reminder_sms || response.data.data.flags.sms_invitation) ){
                    setExpanded('panel2')
                }
                else{
                    setExpanded('panel1')
                }

                if (response.data.data.hasOwnProperty("thankyou_text")) {

                    setThankyouText(convertHtmlToEditorState(response.data.data.thankyou_text.question_text[language]))
                    const thankyou_text = {
                        "en": language === 'en' ? response.data.data.thankyou_text.question_text[language] : '',
                        "es": language === 'es' ? response.data.data.thankyou_text.question_text[language] : '',
                        "vi": language === 'vi' ? response.data.data.thankyou_text.question_text[language] : ''
                    }
                    setThankyouObject(thankyou_text)
                    if (response.data.data.thankyou_option.length > 1) {
                        setCallbackChecked(true)
                    } else {
                        setCallbackChecked(false)
                    }
                }

            }


            //invitations & reminders
            if (response.data.data.invitations_reminders) {
                setMessageText(response.data.data.invitations_reminders.sms_invitation.body[language])
                setEmailSubject(response.data.data.invitations_reminders.email_invitation.subject[language])
                setEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.email_invitation.body[language]))
                setFirstMessageText(response.data.data.invitations_reminders.first_reminder_sms.body[language])
                setFirstEmailSubject(response.data.data.invitations_reminders.first_reminder_email.subject[language])
                setFirstEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.first_reminder_email.body[language]))
                setFinalMessageText(response.data.data.invitations_reminders.final_reminder_sms.body[language])
                setFinalEmailSubject(response.data.data.invitations_reminders.final_reminder_email.subject[language])
                setFinalEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.final_reminder_email.body[language]))
                payload.invite_reminder_list[0].schedule_date = response.data.data.invitations_reminders.sms_invitation.schedule_date
                payload.invite_reminder_list[1].schedule_date = response.data.data.invitations_reminders.email_invitation.schedule_date
                payload.invite_reminder_list[2].schedule_date = response.data.data.invitations_reminders.first_reminder_sms.schedule_date
                payload.invite_reminder_list[3].schedule_date = response.data.data.invitations_reminders.first_reminder_email.schedule_date
                payload.invite_reminder_list[4].schedule_date = response.data.data.invitations_reminders.final_reminder_sms.schedule_date
                payload.invite_reminder_list[5].schedule_date = response.data.data.invitations_reminders.final_reminder_email.schedule_date
            }

            //questions

            if (Object.keys(response.data.data.template[0].ref_answers).length !== 0){
                setQuestions(response.data.data.template[0].ref_questions)
                setAnswers(response.data.data.template[0].ref_answers)
            }
            else{
                // response.data.data.template[0].ref_questions.length === 0;
                setQuestions([
                    {
                        question_id: "refQ1",
                        question_text: {
                            en: "",
                            es: "",
                            vi: ""
                        },
                        answer_type_id: "SSO",
                        is_last_question: 0
                    }
                ]);

                setAnswers({
                    "refQ1": [
                        {
                            "answer_id": "refA1",
                            "answer_text": {
                                "en": "",
                                "es": "",
                                "vi": ""
                            },
                            "answer_type_id": "SSO",
                            "future_question_id": 0,
                            "future_questions": [],
                            "is_copay": false,
                            "is_selected": false,
                            "next_question_id": "refQ2",
                            "copay_future_id": 0,
                            "priority": 0
                        },
                        {
                            "answer_id": "refA2",
                            "answer_text": {
                                "en": "",
                                "es": "",
                                "vi": ""
                            },
                            "answer_type_id": "SSO",
                            "future_question_id": 0,
                            "future_questions": [],
                            "is_copay": false,
                            "is_selected": false,
                            "next_question_id": "refQ2",
                            "copay_future_id": 0,
                            "priority": 0
                        }
                    ]
                });
            }

            //isDraft
            setisDraft(response.data.data.is_draft)

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('There was an error!', error);
            // handleOKButton()
        }
    };


    useEffect(() => {
        setInfoLangChange(false);
        setLanguage('en')
        if (isOpen) {

            localStorage.setItem('Survey_reference',null);

            setSurveyTemplateDisplay(true)
            setSurveyQuestionDisplay(false)
            setSmsInvitationDisplay(false)
            setEmailInvitationDisplay(false)
            setQuestionIndex(null)
            setSelectedSmsEmail('')
            setExpanded('panel1')
            setWelcomeTemplateDisplay(false)
            setThankYouTemplateDisplay(false)

            if (code && code !== 'create_new' ){
                setIsInfoDisplay(false)
                setIsDetailsDisplay(true)
            }
            else{
                // setIsInfoDisplay(true)
                // setIsDetailsDisplay(false)

                if (code === "create_new"){
                    setIsInfoDisplay(true)
                    setIsDetailsDisplay(false)
                    resetState()
                }
                else{
                    removeLastQuestionAndAnswers()
                }

                // resetState()
            }

            if (code && code !== 'create_new' && !localStorage.getItem('surveyCreation')){
                // if (code && code !== 'create_new' ){
                setIsLoading(true);
                fetchData();
            }
            else{
                if(flags.question && automaticReminder && (flags.email_invitation || flags.final_reminder_email || flags.final_reminder_sms || flags.first_reminder_email || flags.first_reminder_sms || flags.sms_invitation )){
                    setExpanded('all')
                }else if(automaticReminder && (flags.email_invitation || flags.final_reminder_email || flags.final_reminder_sms || flags.first_reminder_email || flags.first_reminder_sms || flags.sms_invitation )){
                    setExpanded('panel2')
                }
                else{
                    setExpanded('panel1')
                }
            }


        }
    }, [isOpen]);
    const boxStyle = {
        '--box-width': isInfoDisplay ? '50%' : '75%',
        '--box-height': isInfoDisplay ? '50%' : '85%',
    };



    // const [text, setText] = React.useState();

    return (
        <div >
            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">


                <Box className={"createSurveyPopupcontainer"} style={boxStyle} >
                    {isLoading &&
                        <CommoncircularProgress></CommoncircularProgress>
                    }
                    <Grid container >
                        <Grid item xs={12} className={"GridIcon"} onClick={handleClickcancel}>
                            <img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="15px" height="15px" />
                        </Grid>

                        {isInfoDisplay && (<Grid item xs={12} className={"Gridtext"}>
                            <Grid container className={"accordian"}>

                                <Grid item xs={12} >

                                    <Grid container className='surveyQuestionDiv' >
                                        <Grid item xs={12} >
                                            <Grid item xs={12} className='descDiv'>
                                                <div className="label_text">Survey Name</div>
                                                <TextField
                                                    className={"textField"}
                                                    value={surveyName}
                                                    onChange={handleSurveyNameChange}

                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{ maxLength: 64 }}
                                                    placeholder="Type your survey name..."
                                                    disabled={disabledflag} />
                                                {surveyNameError && <span className='errorMsg'>{surveyNameError}</span>}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='descDiv'>
                                            <div className="label_text">Description</div>
                                            <TextField
                                                className={"textField"}
                                                id="outlined-multiline-flexible"
                                                multiline
                                                value={surveyDesc}
                                                rows={3}
                                                onChange={handleSurveyDescChange}
                                                inputProps={{ maxLength: 255 }}
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Write a description here..."
                                                disabled={disabledflag} />
                                            {surveyDescError && <span className='errorMsg'>{surveyDescError}</span>}

                                        </Grid>

                                        <Grid item xs={12} className='saveCancelDiv'>

                                            <Button
                                                variant="contained"
                                                aria-label="Save"
                                                type="submit"
                                                disabled={surveyName == '' || surveyName == null || surveyDesc == '' || surveyDesc == null || disabledflag}
                                                onClick={infoSave}
                                                className="Savebtn">
                                                Save
                                            </Button>

                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>
                        </Grid>
                        )}
                        {isDetailsDisplay && (
                            <Grid xs={12} className={"Gridtext"}>
                                <Grid container className={"accordian"}>
                                    <Grid xs={3} className='menuDiv'>
                                        {/* <button className={surveyTemplateDisplay ? 'surveyBtn active' : 'surveyBtn'} onClick={() => surveyTemplate()}>
                                        Survey Details
                                    </button> */}
                                        {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary className="accordianSummary" expandIcon={<img src="/asset/Agent/accordianIcon.png" className='accordianIcon' alt="upward Icon" />}>Survey Information</AccordionSummary>
                                            <AccordionDetails variant="soft" className="accordianDetails"> */}

                                        {/* <div className=' scrollable-accordian' ref={containerRef}>
                                                {result && result.data.template.ref_questions.map((item, index) => (
                                                    <button key={index} className={index == questionIndex ? 'questions active' : 'questions'} onClick={() => getQuestion(index)}>
                                                        {index + 1}. {item.question_text.en}
                                                    </button>
                                                ))}
                                            </div> */}
                                        {/* <div > */}
                                        <button className={surveyTemplateDisplay ? 'questions active' : 'questions'} onClick={() => surveyTemplate()}>
                                            Survey Details
                                        </button>
                                        <button className={welcomeTemplateDisplay ? 'questions active' : 'questions'} onClick={() => welcomeTemplate()}>
                                            {/* Welcome Template */}
                                            <p style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                                                Welcome Template
                                                <span style={{ marginLeft: 'auto' }}>
                                                    {flags["welcome_template"] ? <img src="/asset/Agent/issue.png" alt="issue Icon" style={{ marginLeft: '10px' }} /> : ""}
                                                </span>
                                            </p>
                                        </button>
                                        <Accordion expanded={(expanded === 'panel1' || expanded === 'all')} onChange={handleChange('panel1')}>
                                            <AccordionSummary className="accordianSummary" expandIcon={<img src="/asset/Agent/accordianIcon.png" className='accordianIcon' alt="upward Icon" />}>Survey Question</AccordionSummary>
                                            <AccordionDetails variant="soft" className="accordianDetails">
                                                <div className='scrollable-accordian' ref={containerRef}>
                                                    {questions && questions.map((item, index) => (
                                                        <button key={index} className={index == questionIndex ? 'questions active' : 'questions'} onClick={() => getQuestion(index)}>
                                                            <p style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                                                                {index + 1}. Question
                                                                <span style={{ marginLeft: 'auto' }}>
                                                                    {!item.question_text['en'] && flags['question'] ? <img src="/asset/Agent/issue.png" alt="issue Icon" style={{ marginLeft: '10px' }} /> : ""}
                                                                </span>
                                                            </p>
                                                        </button>
                                                    ))}
                                                    {(!code || code == 'create_new' )  &&
                                                        <button className='newQuestion' onClick={newQuestion}
                                                            // disabled={(questions.length != payload.survey_template[0].ref_questions.length) || flags["question"]}>
                                                            disabled={
                                                                questions[questions.length -1]["question_text"]["en"] === "" ||
                                                                answers[`refQ${questionIndex+1}`][answers[`refQ${questionIndex+1}`].length - 1]["answer_text"]["en"] === "" ||
                                                                // questions.length !== payload.survey_template[0].ref_questions.length ||
                                                                flags["question"]
                                                            }
                                                        >
                                                            <img className='plusIcon' src="/asset/Agent/plusicon.png" alt="plus Icon" /> New Question
                                                        </button>
                                                    }

                                                    {(code && code !== 'create_new')  &&
                                                        // <button className='newQuestion' onClick={newQuestion} disabled={(questions.length != questions.filter(question =>
                                                        //     question.question_text['en'].trim() !== ""
                                                        // ).length) || flags["question"]}>
                                                        //     <img className='plusIcon' src="/asset/Agent/plusicon.png" alt="plus Icon" /> New Question
                                                        // </button>
                                                        <button className='newQuestion' onClick={newQuestion}
                                                        disabled={
                                                            questions[questions.length -1]["question_text"]["en"] === "" ||
                                                            answers[`refQ${questionIndex+1}`][answers[`refQ${questionIndex+1}`].length - 1]["answer_text"]["en"] === "" ||
                                                            // questions.length !== payload.survey_template[0].ref_questions.length ||
                                                            flags["question"]
                                                        }
                                                        >
                                                            <img className='plusIcon' src="/asset/Agent/plusicon.png" alt="plus Icon" /> New Question
                                                        </button>
                                                    }
                                                </div>

                                            </AccordionDetails>
                                        </Accordion>
                                        <button className={thankYouTemplateDisplay ? 'questions active' : 'questions'} onClick={() => thankYouTemplate()}>
                                            {/* Thank You Template */}
                                            <p style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                                                Thank You Template
                                                <span style={{ marginLeft: 'auto' }}>
                                                    {flags["thankyou_template"] ? <img src="/asset/Agent/issue.png" alt="issue Icon" style={{ marginLeft: '10px' }} /> : ""}
                                                </span>
                                            </p>
                                        </button>
                                        {/* </div> */}

                                        {/* </AccordionDetails>
                                        </Accordion> */}

                                        <Accordion expanded={(expanded === 'panel2' || expanded === 'all')} onChange={handleChange('panel2')}>
                                            <AccordionSummary className="accordianSummary" expandIcon={<img src="/asset/Agent/accordianIcon.png" className='accordianIcon' alt="upward Icon" />}>Invitations & Reminders</AccordionSummary>
                                            <AccordionDetails variant="soft" className="accordianDetails">
                                                <div >
                                                    {invitations_reminders.map((item, index) => (
                                                        <button
                                                        key={index}
                                                        className={
                                                            (selectedSmsEmail === item.id)
                                                                ? 'questions active'
                                                                : 'questions'
                                                        } onClick={() => getInvitationReminder(item.id)}>
                                                            <p style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                                                                {item.title}
                                                                <span style={{ marginLeft: 'auto' }}>
                                                                    {flags[item.id] && automaticReminder? <img src="/asset/Agent/issue.png" alt="issue Icon" style={{ marginLeft: '10px' }} /> : ""}
                                                                </span>
                                                            </p>

                                                        </button>
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                    </Grid>
                                    <Grid xs={9} >
                                        {surveyTemplateDisplay && (
                                            <Grid container spacing={2} alignItems="center" style={{ position: 'relative' }}>
                                                <Grid container xs={12} className='surveyDiv'><Grid xs={12} style={{ position: 'relative' }} ref={inputRef}>
                                                    <div className="label_text">Survey Name</div>
                                                    <TextField
                                                        className={"textField"}
                                                        value={surveyName}
                                                        onFocus={() => handleFocus('surveyName', surveyName)}
                                                        onBlur={() => handleBlur('surveyName')}
                                                        onChange={handleSurveyNameChange}
                                                        inputProps={{ maxLength: 64 }}
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Survey Name"
                                                        disabled={disabledflag} />
                                                    {surveyNameError && <span className='errorMsg'>{surveyNameError}</span>}
                                                    {surveyNameFocus && (
                                                        <div style={{ position: 'absolute', bottom: '-28px', right: '5%', display: 'flex', alignItems: 'center' }}>
                                                            <div className='tickBox' onClick={infoSave}>
                                                                <img className='iconTickClose' src="/asset/Agent/tick.png" alt="update" />
                                                            </div>

                                                            <div className='closeBox' onClick={() => handleTextFieldCancel('surveyName')}>
                                                                <img className='iconTickClose' src="/asset/Agent/close.png" alt="cancel" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Grid>
                                                    <Grid xs={12} className='descDiv'>
                                                        <div className="label_text">Description</div>
                                                        <TextField
                                                            className={"textField"}
                                                            id="outlined-multiline-flexible"
                                                            multiline
                                                            value={surveyDesc}
                                                            rows={3}
                                                            onFocus={() => handleFocus('surveyDescription', surveyDesc)}
                                                            onBlur={() => handleBlur('surveyDescription')}
                                                            onChange={handleSurveyDescChange}
                                                            inputProps={{ maxLength: 255 }}
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder="Enter your text here..."
                                                            disabled={disabledflag} />
                                                        {surveyDescError && <span className='errorMsg'>{surveyDescError}</span>}
                                                        {surveyDescFocus && (
                                                            <div style={{ position: 'absolute', bottom: '-28px', right: '5%', display: 'flex', alignItems: 'center' }}>
                                                                <div className='tickBox' onClick={infoSave}><img className='iconTickClose' src="/asset/Agent/tick.png" alt="update" /></div>

                                                                <div className='closeBox' onClick={() => handleTextFieldCancel('surveyDescription')}><img className='iconTickClose' src="/asset/Agent/close.png" alt="cancel" /></div>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {welcomeTemplateDisplay && (
                                            <Grid container xs={12} className='surveyQuestionDiv' style={{ position: 'relative' }}>
                                                <Grid xs={10} >
                                                    <h3 className='surveyText'>{surveyName}</h3>
                                                </Grid>
                                                <Grid xs={2}>
                                                    <div className="label_text"></div>
                                                    <TextField
                                                        className={"textField"}
                                                        fullWidth
                                                        select

                                                        variant="outlined"
                                                        value={language}
                                                        onChange={languageChange}
                                                    >
                                                        <MenuItem value="en">English</MenuItem>
                                                        <MenuItem value="es">Spanish</MenuItem>
                                                        <MenuItem value="vi">Vietnamese</MenuItem>
                                                    </TextField>
                                                </Grid>

                                                <Grid xs={12} className='richTextEditorWelcomeThankyou' style={{
                                                    border: isRichEditorFocused ? '2px solid #2194F2' : '1px solid gray',
                                                    padding: '10px',
                                                    marginTop: '10px',
                                                    opacity: disabledflag ? 0.7 : 1,
                                                    pointerEvents: disabledflag ? 'none' : 'auto'
                                                }}>

                                                    <Editor
                                                        editorState={welcomeText}
                                                        onEditorStateChange={handleWelcomeTextChange}
                                                        wrapperClassName="demo-wrapper"
                                                        editorClassName="demo-editor"
                                                        placeholder="Type welcome text here..."
                                                        onFocus={() => handleFocus('welcomeText', welcomeText)}
                                                        onBlur={() => handleBlur('welcomeText')}
                                                        toolbar={{
                                                            options: ['history', 'inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: false },
                                                            textAlign: { inDropdown: false },
                                                            link: { inDropdown: false },
                                                            history: { inDropdown: false },
                                                        }}
                                                        ref={editorRef}
                                                    />

                                                </Grid>
                                                {welcomeTextError && <span className='errorMsg'>{welcomeTextError}</span>}
                                                {welcomeTextFocus && (
                                                    <div style={{ position: 'absolute', bottom: '-28px', right: '5%', display: 'flex', alignItems: 'center' }}>
                                                        <div className='tickBox' onClick={welcomeSave}>
                                                            <img className='iconTickClose' src="/asset/Agent/tick.png" alt="update" />
                                                        </div>

                                                        <div className='closeBox' onClick={() => handleTextFieldCancel('welcomeText')}>
                                                            <img className='iconTickClose' src="/asset/Agent/close.png" alt="cancel" />
                                                        </div>
                                                    </div>
                                                )}

                                            </Grid>
                                        )}
                                        {thankYouTemplateDisplay && (
                                            <Grid container xs={12} className='surveyQuestionDiv' style={{ position: 'relative' }}>
                                                <Grid xs={10} >
                                                    <h3 className='surveyText'>{surveyName}</h3>
                                                </Grid>
                                                <Grid xs={2}>
                                                    <div className="label_text"></div>
                                                    <TextField
                                                        className={"textField"}
                                                        fullWidth
                                                        select

                                                        variant="outlined"
                                                        value={language}
                                                        onChange={languageChange}
                                                    >
                                                        <MenuItem value="en">English</MenuItem>
                                                        <MenuItem value="es">Spanish</MenuItem>
                                                        <MenuItem value="vi">Vietnamese</MenuItem>
                                                    </TextField>
                                                </Grid>


                                                <Grid xs={12} className='richTextEditorWelcomeThankyou' style={{
                                                    border: isRichEditorFocused ? '2px solid #2194F2' : '1px solid gray',
                                                    padding: '10px',
                                                    marginTop: '10px',
                                                    opacity: disabledflag ? 0.7 : 1,
                                                    pointerEvents: disabledflag ? 'none' : 'auto'
                                                }}
                                                >

                                                    <Editor
                                                        editorState={thankyouText}
                                                        onEditorStateChange={handleThankyouTextChange}
                                                        wrapperClassName="demo-wrapper"
                                                        editorClassName="demo-editor"
                                                        placeholder="Type thank you text here..."
                                                        toolbar={{
                                                            options: ['history', 'inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: false },
                                                            textAlign: { inDropdown: false },
                                                            link: { inDropdown: false },
                                                            history: { inDropdown: false },
                                                        }}
                                                        ref={editorRef}
                                                    />
                                                </Grid>
                                                {thankyouTextError && <span className='errorMsg'>{thankyouTextError}</span>}

                                                <Grid xs={12} spacing={2} className='saveCancelDiv'>

                                                    <Button
                                                        variant="contained"
                                                        aria-label="Save"
                                                        type="submit"
                                                        disabled={!thankyouText || thankyouTextError || disabledflag}
                                                        onClick={thankyouSave}
                                                        className="Savebtn">
                                                        Save
                                                    </Button>

                                                </Grid>

                                            </Grid>
                                        )}

                                        {surveyQuestionDisplay && (
                                            <Grid container spacing={2} alignItems="center" style={{ position: 'relative' }}>
                                                {questionIndex != null && questionIndex != 0 && (
                                                <img className="arrow arrowLeft" src="/asset/Agent/arrowdowncampaign.png" alt="Left Icon" onClick={() => prevQuestion()} />
                                            )}
                                            {questionIndex != questions.length - 1 && (
                                                <img className="arrow arrowRight" src="/asset/Agent/arrowdowncampaign.png" alt="Right Icon" onClick={() => nextQuestion()} />
                                            )}


                                                <Grid container className='surveyQuestionDiv'>
                                                    <Grid xs={10} >
                                                        <h3 className='surveyText'>{surveyName}</h3>
                                                    </Grid>
                                                    <Grid xs={2}>
                                                        <div className="label_text"></div>
                                                        <TextField
                                                            className={"textField"}
                                                            fullWidth
                                                            select

                                                            variant="outlined"
                                                            value={language}
                                                            onChange={languageChange}
                                                        >
                                                            <MenuItem value="en">English</MenuItem>
                                                            <MenuItem value="es">Spanish</MenuItem>
                                                            <MenuItem value="vi">Vietnamese</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                </Grid>

                                                <Grid xs={12} className='scrollbar-space '>
                                                    <Grid xs={12} className={questions.length > 1 ? "GridIcon" : "GridIcon iconDisable"} >
                                                        {questions.length > 1 && <img src="/asset/Agent/deleteIcon.png" alt="delete Icon" width="20px" height="20px" onClick={questions.length > 1 ? deleteQuestion : ''} />}
                                                    </Grid>
                                                    <div className='questionBox scrollable-box' >
                                                        <div style={{ position: 'relative' }}>
                                                            <TextField
                                                                className={"textField"}
                                                                id="outlined-basic"
                                                                value={question}
                                                                // onFocus={() => handleFocus('question',question)}
                                                                onBlur={() => handleBlur('question')}
                                                                onChange={questionChange}
                                                                variant="outlined"
                                                                fullWidth
                                                                rows={2}
                                                                multiline
                                                                placeholder={`Enter survey question #${questionIndex + 1} ...`}
                                                                disabled={disabledflag}
                                                            />
                                                            {questionError && <span className='errorMsg'>{questionError}</span>}
                                                            {focusStates.question && (
                                                                <div style={{ position: 'absolute', bottom: '-32px', right: '5%', display: 'flex', alignItems: 'center', zIndex: 999 }}>
                                                                    <div className='tickBox'><img className='iconTickClose' src="/asset/Agent/tick.png" alt="update" /></div>

                                                                    <div className='closeBox'><img className='iconTickClose' src="/asset/Agent/close.png" alt="cancel" /></div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {selectedAnswers && (<div className='answerBox'>
                                                            {selectedAnswers.map((item, index) => (
                                                                <div key={index} style={{ position: 'relative', marginBottom: '20px' }}>
                                                                    <TextField
                                                                        value={item.answer_text[language]}
                                                                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                                                                        onFocus={() => handleAnswerFocus(index)}
                                                                        onBlur={() => handleAnswerBlur(index)}
                                                                        id={`outlined-basic-${index}`}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        placeholder={`Response ${index + 1}`}
                                                                        disabled={disabledflag}
                                                                    />
                                                                    {focusAnswerStates[index] && selectedAnswers.length > 2 && (
                                                                        <div style={{ position: 'absolute', bottom: '2px', right: '-32px', display: 'flex', alignItems: 'center', zIndex: 99999 }}>
                                                                            <div
                                                                                className={selectedAnswers.length > 2 ? "deleteBox" : "deleteBox iconDisable"}
                                                                                onMouseDown={(e) => {
                                                                                    e.stopPropagation();
                                                                                    deleteAnswer(index);
                                                                                }}
                                                                                style={{ cursor: selectedAnswers.length > 2 ? 'pointer' : 'default' }} // Ensure cursor style
                                                                            >
                                                                                <img className='iconAnswerDelete' src="/asset/Agent/answerDeleteIcon.png" alt="delete" disabled={disabledflag} />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>)}

														{selectedAnswers && selectedAnswers.length < 4 && (
															<div className='addBoxMain'>
																<div className='addBox' onClick={addAnswer} disabled={disabledflag}><img className='iconAnswerAdd' src="/asset/Agent/answerAddIcon.png" alt="update" /></div>
															</div>
														)}
                                                    </div>

                                                    <Grid xs={12} spacing={2} className='saveCancelDiv'>

                                                        <Button
                                                            variant="contained"
                                                            aria-label="Save"
                                                            type="submit"
                                                            disabled={!question || (!selectedAnswers.every(item =>
                                                                item.answer_text && item.answer_text[language] && item.answer_text[language].trim() !== ""
                                                            )) || disabledflag}
                                                            onClick={questionAnswerSave}
                                                            className="Savebtn" >
                                                            Save
                                                        </Button>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {smsInvitationDisplay && (
                                            <Grid container className='surveyQuestionDiv'>
                                                <Grid xs={10} >
                                                    <h3 className='surveyText'>{surveyName}</h3>
                                                    <p className='smsDesc'>{selectedDescription}</p>
                                                </Grid>
                                                <Grid xs={2}>
                                                    <div className="label_text"></div>
                                                    <TextField
                                                        className={"textField"}
                                                        fullWidth
                                                        select

                                                        variant="outlined"
                                                        value={language}
                                                        onChange={languageChange}
                                                    >
                                                        <MenuItem value="en">English</MenuItem>
                                                        <MenuItem value="es">Spanish</MenuItem>
                                                        <MenuItem value="vi">Vietnamese</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                <Grid xs={12} className='descDiv'>
                                                    <div className="label_text">Message Text</div>
                                                    <TextField
                                                        className={"textField"}
                                                        id="outlined-multiline-flexible"
                                                        multiline
                                                        value={selectedSmsEmail === 'sms_invitation' ? messageText : selectedSmsEmail === 'first_reminder_sms' ? firstmessageText : finalmessageText}
                                                        rows={3}
                                                        onChange={messageTextChange}
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Type message here..."
                                                        disabled={disabledflag} />
                                                    {messageTextError && <span className='errorMsg'>{messageTextError}</span>}
                                                </Grid>
                                                <Grid xs={12} spacing={2} className='saveCancelDiv'>
                                                    <Button
                                                        variant="contained"
                                                        aria-label="Save"
                                                        type="submit"
                                                        disabled={messageTextError || disabledflag}
                                                        onClick={smsEmailSave}
                                                        className="Savebtn" >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {emailInvitationDisplay && (
                                            <Grid container className='surveyQuestionDiv'>
                                                <Grid xs={10} >
                                                    <h3 className='surveyText'>{surveyName}</h3>
                                                    <p className='smsDesc'>{selectedDescription}</p>
                                                </Grid>
                                                <Grid xs={2}>
                                                    <div className="label_text"></div>
                                                    <TextField
                                                        className={"textField"}
                                                        fullWidth
                                                        select
                                                        variant="outlined"
                                                        value={language}
                                                        onChange={languageChange}
                                                    >
                                                        <MenuItem value="en">English</MenuItem>
                                                        <MenuItem value="es">Spanish</MenuItem>
                                                        <MenuItem value="vi">Vietnamese</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                <Grid xs={12} className='descDiv'>
                                                    <div className="label_text">Subject</div>
                                                    <TextField
                                                        className={"textField"}
                                                        value={selectedSmsEmail === 'email_invitation' ? emailSubject : selectedSmsEmail === 'first_reminder_email' ? firstemailSubject : finalemailSubject}
                                                        onChange={emailSubjectChange}
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        inputProps={{ maxLength: 255 }}
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Enter subject line..."
                                                        disabled={disabledflag} />
                                                    {subjectError && <span className='errorMsg'>{subjectError}</span>}

                                                </Grid>
                                                <div className="body_text_label" >Body Text</div>
                                                <Grid xs={12} className='richTextEditor'
                                                sx = {{
                                                    opacity: disabledflag ? 0.7 : 1,
                                                    pointerEvents: disabledflag ? 'none' : 'auto'
                                                }}>

                                                    <Editor
                                                        editorState={selectedSmsEmail === 'email_invitation' ? emailBody : selectedSmsEmail === 'first_reminder_email' ? firstemailBody : finalemailBody}
                                                        onEditorStateChange={handleEmailBodyTextChange}
                                                        wrapperClassName="demo-wrapper"
                                                        editorClassName="demo-editor"
                                                        placeholder=""
                                                        toolbar={{
                                                            options: ['history', 'inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: false },
                                                            textAlign: { inDropdown: false },
                                                            link: { inDropdown: false },
                                                            history: { inDropdown: false },
                                                        }}
                                                        ref={editorRef}
                                                    />
                                                </Grid>
                                                <Grid xs={12} spacing={2} className='saveCancelDiv'>
                                                    <Button
                                                        variant="contained"
                                                        aria-label="Save"
                                                        type="submit"
                                                        disabled={subjectError || disabledflag}
                                                        onClick={smsEmailSave}
                                                        className="Savebtn" >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}

                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <UpdateSurveyName childModalopen={childModalopen} setChildModalOpen={setChildModalOpen} surveyName={surveyName} updatedSurveyName={handleupdatedSurveyName} campaignType="survey" surveyUpdateVariant={surveyUpdateVariant} namesArray={surveyArray}/>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )

}

export default AutoResponsePopup;
